.buildStage {
    width: 100%;
    height: 95vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: turquoise;
}