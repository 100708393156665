.naviContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc( 100% - 60px);

    padding: 15px 30px;
    background-color: white;
    color: #334;
}

.naviPoints {
    display: flex;
    
}

.logoLink {
    text-decoration: none;
    color: #334;
}

@media (max-width: 1000px){
    .logo {
        display: none;
    }

    .naviContainer {
        position: fixed;
        bottom: 0;
        justify-content: center;
    }
}

.new {
    display: none;
}