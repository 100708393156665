.textBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;

    width: 100%;
    
}

.textText {
    width: 95%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.textText p {
    width: 100%;
    padding: 10px 0 10px;
}

.textText p:first-of-type {
    padding-top: 0;
}