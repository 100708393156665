/* .placeholderContainer {

    height: calc(100vh - 49px);
    width: 100%;
    background-color: rgb(27, 27, 29);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.placeholderContainer h1 {
    font-size: calc(5vw + 30px);
    z-index: 3;
    background-color: rgb(27, 27, 29);
    padding: 1% 2%;
}
span {
    transform: rotate(-36deg);
    height: 1vw;
    width: 200px;
    margin: 15px 0;
    background-color: tomato;

    animation-name: breite;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-delay: 4s;
}

.placeholderLL {
    position: absolute;
    left: 0px;
    bottom: 10%;
    display: flex;
    flex-direction: column;
}

.placeholderLLA {
    position: absolute;
    left: 0px;
    bottom: 10%;
    display: flex;
    flex-direction: column;
}

.placeholderLR {
    position: absolute;
    right: 0;
    top: 10%;
    display: flex;
    flex-direction: column;
}

.placeholderLRA {
    position: absolute;
    right: 0;
    top: 10%;
    display: flex;
    flex-direction: column;
}

.placeholderLRA > span {
    animation-name: breite;
    animation-duration: 8s;
    animation-iteration-count: infinite;

    transform: rotate(-36deg);
    height: 1vw;
    margin: 15px 0;
    background-color: gold;
}

.placeholderLLA > span {
    animation-name: breite;
    animation-duration: 8s;
    animation-iteration-count: infinite;

    transform: rotate(-36deg);
    height: 1vw;
    margin: 15px 0;
    background-color: gold;
}

@keyframes breite {
    0% { width: 0px}
    50% { width: 200px}
    100% { width: 0px}
} */

header.home{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    height: 40vh;
}