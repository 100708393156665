.naviPoint {
    padding: 10px 15px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-weight: 600;
}

.naviPoint:hover {
    opacity: 0.6;
}

.naviPointIcon {
    display: flex;
    flex-direction: column;
    align-items: center;


    width: auto;
    margin: 0 10vw;

    text-decoration: none;
    font-size: 12px;
    color: #334;
    font-weight: 600;
}

.naviPointIcon Img {
    width: 30px;
}

@media (max-width: 1000px) {
    .naviPoint {
        display: none;
    }
}

@media (min-width: 1000px) {
    .naviPointIcon {
        display: none;
    }
}