.blogArticleBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
}

.blogArticleText {
    width: 90%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 100px;
}

.blogArticleText p {
    width: 100%;
    padding: 10px 0;
}

.blogArticleText p:first-of-type {
    padding-top: 0;
}

.blogArticleText h2 {
    margin-top: 0;
    width: 100%;
}

.blogArticleHeader {
    width: 90%;
    max-width: 1100px;
    margin-bottom: 69px;
}

img {
    width: 100%;
}

a {
    color: #334;
}

.blogArticleFooter {
    color: #666666;
    font-style: italic;
    margin-top: 30px;
    padding-top: 10px !important;
    border-top: 2px solid #aaaaaa;
}

@media (min-width: 770px) {

    .blogArticleText {
        width: 70%;
    }

    .blogArticleText p {
        width: 100%;
        padding: 10px 0;
    }
    
    .blogArticleText h2 {
        margin-top: 0;
        width: 85%;
    }
}

@media (max-width: 1000px) {
    .blogArticleBody {
        padding-top: 30px;
    }
}