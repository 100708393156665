.blogCardContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 90%;
    height: 100%;
    padding: 2%;
    margin: 30px 0;
    cursor: pointer;
    transition: box-shadow .15s cubic-bezier(.4, 0, .2, 1);
    text-decoration: none;
}

.blogCardContainer:hover {
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12);
}

.blogCardImage {
    width: 100%;
    overflow: hidden;
}

.blogCardText {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 10px 0;
    width: 100%;
    height: 60%;
}

img {
    width: 100%;
}

