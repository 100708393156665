@import url(https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@400;600;700&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 43px;
  font-weight: 700;
}

h2 {
  font-size: 32px;
  font-weight: 700;
}

h3 {
  margin: 30px 0 5px 0;
  font-weight: 600;
  font-size: 24px;
}

p {
  font-weight: 400;
  margin: 0;
  line-height: 1.4;
  font-size: 16px;
  letter-spacing: 0.2px;
  font-family: 'Lato', sans-serif;
}

li {
  padding: 5px 0;
  font-weight: 400;
  margin: 0;
  line-height: 1.4;
  font-size: 16px;
  letter-spacing: 0.2px;
  font-family: 'Lato', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.body {
  overflow-x: hidden;
}

.textBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;

    width: 100%;
    
}

.textText {
    width: 95%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.textText p {
    width: 100%;
    padding: 10px 0 10px;
}

.textText p:first-of-type {
    padding-top: 0;
}
.naviContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc( 100% - 60px);

    padding: 15px 30px;
    background-color: white;
    color: #334;
}

.naviPoints {
    display: flex;
    
}

.logoLink {
    text-decoration: none;
    color: #334;
}

@media (max-width: 1000px){
    .logo {
        display: none;
    }

    .naviContainer {
        position: fixed;
        bottom: 0;
        justify-content: center;
    }
}

.new {
    display: none;
}
.naviPoint {
    padding: 10px 15px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-weight: 600;
}

.naviPoint:hover {
    opacity: 0.6;
}

.naviPointIcon {
    display: flex;
    flex-direction: column;
    align-items: center;


    width: auto;
    margin: 0 10vw;

    text-decoration: none;
    font-size: 12px;
    color: #334;
    font-weight: 600;
}

.naviPointIcon Img {
    width: 30px;
}

@media (max-width: 1000px) {
    .naviPoint {
        display: none;
    }
}

@media (min-width: 1000px) {
    .naviPointIcon {
        display: none;
    }
}
.buildStage {
    width: 100%;
    height: 95vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: turquoise;
}
/* .placeholderContainer {

    height: calc(100vh - 49px);
    width: 100%;
    background-color: rgb(27, 27, 29);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.placeholderContainer h1 {
    font-size: calc(5vw + 30px);
    z-index: 3;
    background-color: rgb(27, 27, 29);
    padding: 1% 2%;
}
span {
    transform: rotate(-36deg);
    height: 1vw;
    width: 200px;
    margin: 15px 0;
    background-color: tomato;

    animation-name: breite;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-delay: 4s;
}

.placeholderLL {
    position: absolute;
    left: 0px;
    bottom: 10%;
    display: flex;
    flex-direction: column;
}

.placeholderLLA {
    position: absolute;
    left: 0px;
    bottom: 10%;
    display: flex;
    flex-direction: column;
}

.placeholderLR {
    position: absolute;
    right: 0;
    top: 10%;
    display: flex;
    flex-direction: column;
}

.placeholderLRA {
    position: absolute;
    right: 0;
    top: 10%;
    display: flex;
    flex-direction: column;
}

.placeholderLRA > span {
    animation-name: breite;
    animation-duration: 8s;
    animation-iteration-count: infinite;

    transform: rotate(-36deg);
    height: 1vw;
    margin: 15px 0;
    background-color: gold;
}

.placeholderLLA > span {
    animation-name: breite;
    animation-duration: 8s;
    animation-iteration-count: infinite;

    transform: rotate(-36deg);
    height: 1vw;
    margin: 15px 0;
    background-color: gold;
}

@keyframes breite {
    0% { width: 0px}
    50% { width: 200px}
    100% { width: 0px}
} */

header.home{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    height: 40vh;
}
.blogOverviewBackground {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 76px 0;
}

.blogOverviewContainer {
    width: 80%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

@media (max-width: 1000px){
    .blogOverviewContainer {
        flex-direction: column;
        align-items: center;
    }
}
.blogCardContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 90%;
    height: 100%;
    padding: 2%;
    margin: 30px 0;
    cursor: pointer;
    transition: box-shadow .15s cubic-bezier(.4, 0, .2, 1);
    text-decoration: none;
}

.blogCardContainer:hover {
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12);
}

.blogCardImage {
    width: 100%;
    overflow: hidden;
}

.blogCardText {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 10px 0;
    width: 100%;
    height: 60%;
}

img {
    width: 100%;
}


.blogArticleBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
}

.blogArticleText {
    width: 90%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 100px;
}

.blogArticleText p {
    width: 100%;
    padding: 10px 0;
}

.blogArticleText p:first-of-type {
    padding-top: 0;
}

.blogArticleText h2 {
    margin-top: 0;
    width: 100%;
}

.blogArticleHeader {
    width: 90%;
    max-width: 1100px;
    margin-bottom: 69px;
}

img {
    width: 100%;
}

a {
    color: #334;
}

.blogArticleFooter {
    color: #666666;
    font-style: italic;
    margin-top: 30px;
    padding-top: 10px !important;
    border-top: 2px solid #aaaaaa;
}

@media (min-width: 770px) {

    .blogArticleText {
        width: 70%;
    }

    .blogArticleText p {
        width: 100%;
        padding: 10px 0;
    }
    
    .blogArticleText h2 {
        margin-top: 0;
        width: 85%;
    }
}

@media (max-width: 1000px) {
    .blogArticleBody {
        padding-top: 30px;
    }
}
