.blogOverviewBackground {
    width: 100%;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 76px 0;
}

.blogOverviewContainer {
    width: 80%;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

@media (max-width: 1000px){
    .blogOverviewContainer {
        flex-direction: column;
        align-items: center;
    }
}