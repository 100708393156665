@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@400;600;700&display=swap');
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 43px;
  font-weight: 700;
}

h2 {
  font-size: 32px;
  font-weight: 700;
}

h3 {
  margin: 30px 0 5px 0;
  font-weight: 600;
  font-size: 24px;
}

p {
  font-weight: 400;
  margin: 0;
  line-height: 1.4;
  font-size: 16px;
  letter-spacing: 0.2px;
  font-family: 'Lato', sans-serif;
}

li {
  padding: 5px 0;
  font-weight: 400;
  margin: 0;
  line-height: 1.4;
  font-size: 16px;
  letter-spacing: 0.2px;
  font-family: 'Lato', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
